<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Job overview for {{ job.jobNumber }}
          | Job Status:
          <span v-if="job.status === 0">Not planned</span>
          <span v-if="job.status === 1">Planned</span>
          <span v-if="job.status === 2">Complete Awaiting Approval</span>
          <span v-if="job.status === 3">Authorised</span>
          <span v-if="job.status === 4">Invoiced</span>
          <span class="float-right">Overview</span>
          &nbsp;|&nbsp;
          Total value of quotation: {{ job.value | priceInPounds | currency }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <p>
          <router-link :to="`/live-jobs/view/${job.id}`">
            <v-btn small>View job card</v-btn>
          </router-link>
          <span>&nbsp;</span>
          <router-link
          v-if="job.authorised_to_invoice === false"
            :to="`/live-jobs/update/${job.id}`">
            <v-btn small>Update job details</v-btn>
          </router-link>
          <span v-if="job.authorised_to_invoice === false">&nbsp;</span>
          <router-link
            v-if="job.authorised_to_invoice === false"
            :to="`/live-jobs/add-lines/${job.id}`">
            <v-btn small>Add/Remove BOQs</v-btn>
          </router-link>
          <span v-if="job.authorised_to_invoice === false">&nbsp;</span>
          <router-link
            v-if="job.authorised_to_invoice === false"
            :to="`/live-jobs/add-documents/${job.id}`">
            <v-btn small>Add Documents</v-btn>
          </router-link>
          <v-btn
            v-if="job.authorised_to_invoice === false"
            small
            @click="takeOverQuotation"
            role="link">
              Take over
          </v-btn>
          <span v-if="job.authorised_to_invoice === false">&nbsp;</span>
          <v-btn
            v-if="job.work_completed && job.authorised_to_invoice === false"
            small
            @click="confirmApprove">Approve</v-btn>
          <span v-if="job.work_completed && job.authorised_to_invoice === false">&nbsp;</span>
          <v-btn
            small
            @click="remedialQuotation"
            role="link">
              Remedial
          </v-btn>
          <span>&nbsp;</span>
          <v-btn
            v-if="userPermissions.includes(3) && job.status !== 3"
            small
            color="orange"
            @click="toAwaitingInvoice"
            role="link">
              To Awaiting Invoice
          </v-btn>
          <span v-if="userPermissions.includes(4) && job.status !== 3">&nbsp;</span>
          <v-btn
            small
            color="red"
            @click="cancelJob"
            role="link">
              Cancel
          </v-btn>
          <span>&nbsp;</span>
          <router-link
            v-if="job.status === 3"
            custom v-slot="{ navigate }"
            :to="`/live-jobs/progress/${job.id}`">
            <v-btn
              title="View"
              small
              @click="navigate"
              @keypress.enter="navigate"
              role="link">
                Progress
            </v-btn>
          </router-link>
          <span v-if="job.status === 3">&nbsp;</span>
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          Customer:<br />
          {{ job.customer }}<br />
          <span v-if="job.customerAddressOne">{{ job.customerAddressOne }}<br /></span>
          <span v-if="job.customerAddressTwo">{{ job.customerAddressTwo }}<br /></span>
          <span v-if="job.customerAddressThree">{{ job.customerAddressThree }}<br /></span>
          <span v-if="job.customerAddressFour">{{ job.customerAddressFour }}<br /></span>
          <span v-if="job.customerAddressFive">{{ job.customerAddressFive }}<br /></span>
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          Site address:<br />
          {{ job.site }}<br />
          {{ job.postCode }}
        </p>
        <p>
          Site contact:<br />
          {{ job.site_contact }}<br />
          {{ job.site_email }}<br />
          {{ job.site_tel }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          Work description:
        </p>
        <div v-html="job.description"></div>
        <p>
          PO:
          {{ job.purchase_order }}<br />
          <a
            v-if="job.purchase_order_uri"
            :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${job.purchase_order_uri}`"
            target="_blank">
            <v-btn x-small>View PO</v-btn>
          </a>
        </p>
        <p class="mb-0">
          Special instructions:
        </p>
        <div class="pb-4" v-html="job.special_instructions"></div>
        <p>
          Notes:
        </p>
        <div v-html="job.notes"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <p>Minimum charges:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(charge, index) in job.minimumCharges" :key="index">
              <td>{{ charge.description }}</td>
              <td>{{ charge.charge | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <p>BOQs (From quotation):</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Material</th>
              <th>Colour</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(line, index) in job.quotationLines"
              :key="index">
              <td>{{ line.boq }}</td>
              <td>{{ line.material }}</td>
              <td>{{ line.colour }}</td>
              <td>{{ line.unit }}</td>
              <td>{{ line.quantity }}</td>
              <td>
                @{{ line.price | priceInPounds | currency }} =
                {{ (line.quantity * line.price) | priceInPounds | currency }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <h3 class="mt-2 mb-4">Total value of quotation: {{ job.value | priceInPounds | currency }}</h3>
      </v-col>
      <v-col class="border-left" cols="4">
        <p>BOQs (From DRS):</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>BOQ</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(drsBoq, index) in job.drsBoqs" :key="index">
              <td>{{ drsBoq.name }}</td>
              <td>{{ drsBoq.quantity }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span v-for="(drs, index) in job.drs" :key="index">
          <router-link :to="`/drs/view/${drs.id}`">
            <v-btn small class="mr-4 mb-4" style="min-width:230px">
              View DRS #{{ index + 1 }} {{ drs.date | tinyDate }}
            </v-btn>
          </router-link>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <p>Materials used (From DRS):</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Colour</th>
              <th>Quantity (bags)</th>
              <th>Temperature</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in job.materials" :key="index">
              <td>{{ material.type }}</td>
              <td>{{ material.colour }}</td>
              <td>{{ material.quantity }}</td>
              <td>{{ material.temperature }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.plannedJobs">
      <v-col cols="12" sm="12">
        <p>Planned work:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Vehicle</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(job, index) in job.plannedJobs" :key="index">
              <td>
                {{ job.date | prettyDate}}
              </td>
              <td>
                {{ job.vehicle }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <p>Crew:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pay bonus?</th>
              <th>Bonus</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(crew, index) in job.drsWorkers" :key="index">
              <td>{{ crew.name }}</td>
              <td>
                <span v-if="crew.payBonus === true || crew.payBonus === 1">Yes</span>
                <span v-else>No</span>
              </td>
              <td>{{ crew.bonus | currency }}</td>
              <td>{{ crew.hours }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <p>Costs:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Mileage</th>
              <th>Vehicle</th>
              <th>MPG</th>
              <th>Fuel</th>
              <th>Running Costs</th>
              <th>Materials</th>
              <th>Bonus</th>
              <th>Overnight</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cost, index) in job.costs" :key="index">
              <td>{{ cost.mileage }}</td>
              <td>{{ cost.vehicle }}</td>
              <td>{{ cost.mpg }}</td>
              <td>{{ ((((cost.fuel * 4.55) /  cost.mpg) * cost.mileage) / 100000) | currency }}</td>
              <td>{{ (cost.mileage * cost.running_cost) | priceInPounds | currency }}</td>
              <td>{{ cost.materials | currency }}</td>
              <td>{{ cost.bonus | currency }}</td>
              <td>{{ cost.overnight | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.drsImages">
      <v-col>
        <p>Images from the DRS:</p>
        <img
          v-for="(image, index) in job.drsImages"
          :src="image.uri"
          :key="index" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="approveBox"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm Approval of job: {{ job.jobNumber }}
        </v-card-title>
        <v-card-text class="pt-4">
          Important, please confirm you have reviewed this job and it is correct
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-2"
            small
            @click="approve"
          >
            {{ $store.state.username }} Approving Job for Invoice
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="cancelApproving"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancelBox"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm Cancellation of job: {{ job.jobNumber }}
        </v-card-title>
        <v-card-text class="pt-4">
          Important, please confirm you want to cancel the job, this cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-textarea v-model="cancelReason" label="Why is the job being cancelled?"></v-textarea>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            small
            @click="sendCancelJob"
          >
            {{ $store.state.username }} Canceling Job
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="(cancelBox = false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="aiBox"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm moving job: {{ job.jobNumber }} to awaiting accounts.
        </v-card-title>
        <v-card-text class="pt-4">
          Important, please confirm you want to move this to awaiting accounts.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            small
            @click="sendToAwaitingInvoice"
          >
            {{ $store.state.username }} Sending Job to Awaiting Invoice
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="(aiBox = false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="800"
    >
      <v-card>
        <v-card-title>{{ message }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link to="/live-jobs/not-approved">
            <v-btn
              color="green lighten-2"
              small
            >
              View all Jobs awaiting approval
            </v-btn>
          </router-link>
          &nbsp;
          <v-btn
            small
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LiveJobOverview',
  data() {
    return {
      job: { id: 0 },
      approveBox: false,
      showMessage: '',
      message: '',
      cancelBox: false,
      cancelReason: '',
      aiBox: false,
    };
  },
  computed: {
    userid() {
      return this.$store.state.userid;
    },
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  methods: {
    toAwaitingInvoice() {
      this.aiBox = true;
    },
    sendToAwaitingInvoice() {
      axios.get(`/liveJobs/toAwaitingInvoice/${this.$route.params.id}.json?token=${this.token}`)
      .then(() => {
        this.$router.push('/live-jobs/awaiting-accounts');
      });
    },
    takeOverQuotation() {
      axios.get(`/quotations/takeOver/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then(() => {
        this.quotation = {};
        this.getQuotation();
        this.quoteTakenOver = true;
      });
    },
    cancelJob() {
      this.cancelBox = true;
    },
    sendCancelJob() {
      const postData = {};
      postData.cancelReason = this.cancelReason;
      axios.post(`/liveJobs/cancel/${this.$route.params.id}.json?token=${this.token}`, postData)
      .then(() => {
        this.$router.push('/live-jobs/index');
      });
    },
    getJob() {
      axios.get(`/liveJobs/getSingleInfo/${this.$route.params.id}/true.json?token=${this.token}`)
      .then((response) => {
        this.job = response.data;
      });
    },
    confirmApprove() {
      this.approveBox = true;
    },
    cancelApproving() {
      this.approveBox = false;
    },
    approve() {
      const jobId = this.job.id;
      axios.get(`/quotations/approveForInvoice/${jobId}/${this.userid}.json?token=${this.token}`)
        .then(() => {
          this.approveBox = false;
          this.showMessage = true;
          this.message = 'The job has been approved for the accounts team to progress';
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remedialQuotation() {
      axios.get(`/quotations/remedial/${this.job.id}/${this.userid}.json?token=${this.token}`)
      .then((response) => {
        this.$router.push(`/quotations/update/${response.data}`);
      });
    },
  },
  mounted() {
    this.getJob();
  },
};
</script>
